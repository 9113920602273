import React from "react";
import { graphql } from "gatsby";
import Trigger from "../components/trigger";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";
import styled, { createGlobalStyle, css } from "styled-components/macro";
import Favicon from "../components/favicon";
import { NormalizeCSS } from "../utils";
import Img from "gatsby-image";
import Footer from "../components/footer";
import { Menu } from "../components/menu";
import CookiePopup from "../components/CookiePopup";

const GlobalStyles = createGlobalStyle`
body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  background: #ffffff;
}
h2, h3, h4 {
  font-weight: 300;
}
h3 {
  font-size: 42px;
  line-height: 52px;
  margin: 0;
}
p {
  font-size: 18px;
  line-height: 28px;
}
a {
  text-decoration: none;
  color: #00e;
}
`;

const Container = styled.div<{ width?: number }>`
  max-width: 1176px;
  ${({ width }) =>
    typeof width !== "undefined" &&
    css`
      max-width: ${width}px;
    `}
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
`;

export default ({
  location: { pathname: path },
  data,
  pageContext: { lang },
  ...props
}) => {
  return (
    <>
      <Trigger name={`pageview ${path}`} />
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://www.datocms-assets.com" />
      </Helmet>
      <SEO
        path={path}
        lang={lang}
        title={data.datoCmsBlog.title}
        metaDescription={data.datoCmsBlog.smallExcerpt}
      />
      <Helmet>
        {data.datoCmsBlog.seoMetaTags.tags.map(
          ({ tagName, content, attributes }, k) =>
            React.createElement(tagName, { key: k, ...attributes }, content),
        )}
        <link rel="canonical" href={`https://framian.fi${path}`} />
      </Helmet>
      <div
        css={`
          background-image: url(${encodeURI(data.datoCmsBlog.heroImage.url)});
          background-position: 50% 50%;
          background-size: cover;
        `}
      >
        <Container>
          <div
            css={`
              display: flex;
              justify-content: flex-start;
              align-items: center;
              min-height: 100vh;
              padding: 30px;
            `}
          >
            <div
              css={`
                color: #000;
                background: #fff;
                box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.125);
                ${data.datoCmsBlog.blackOrWhiteBox &&
                css`
                  color: #fff;
                  background: #000;
                  box-shadow: 0px 0px 32px rgba(255, 255, 255, 0.125);
                `}
                padding: 32px 48px;
                width: 100%;
                max-width: 608px;
                > h1 {
                  font-weight: 300;
                  font-size: 42px;
                  line-height: 52px;
                  margin-bottom: 1em;
                }
                > p {
                  font-weight: 300;
                  font-size: 18px;
                  line-height: 28px;
                  margin-bottom: 1em;
                  white-space: pre-wrap;
                }
                @media (max-width: 768px) {
                  h1 {
                    font-size: 1.75rem;
                    line-height: 1.25;
                  }
                  p {
                    font-size: 1.25rem;
                    line-height: 1.25;
                  }
                }
              `}
            >
              <h1>{data.datoCmsBlog.title}</h1>
              <p>{data.datoCmsBlog.excerpt}</p>
            </div>
          </div>
        </Container>
      </div>
      <Container width={760}>
        <div
          css={`
            padding-top: 70px;
            padding-bottom: 70px;
          `}
        >
          <div
            css={`
              color: #000;
              p {
                margin-bottom: 1em;
                font-size: 18px;
                line-height: 1.5;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                margin: 1em 0;
                font-weight: 300;
              }
              h1 {
                font-size: 2.25rem;
              }
              h2 {
                font-size: 2rem;
              }
              h3 {
                font-size: 1.75rem;
              }
              h4 {
                font-size: 1.5rem;
              }
              h5 {
                font-size: 1.25rem;
              }
              h6 {
                font-size: 1rem;
              }
              ol {
                margin: 1.5em 0;
                list-style-type: decimal;
                list-style-position: outside;
                margin-left: 20px;
              }
              ul {
                margin: 1.5em 0;
                list-style-type: disc;
                list-style-position: outside;
                margin-left: 20px;
              }
              li {
                font-size: 18px;
                line-height: 1.5em;
              }
              a {
                box-shadow: inset 0px -1px 0px 0px #000;
                color: #000;
                &:hover {
                  box-shadow: inset 0px -3px 0px 0px #000;
                }
              }
              blockquote {
                position: relative;
                &::before {
                  font-family: Arial;
                  content: "\u{201c}";
                  color: #000;
                  font-size: 4em;
                  position: absolute;
                  left: -30px;
                  top: -15px;
                }
              }
              td {
                padding: 3px;
              }
            `}
            dangerouslySetInnerHTML={{
              __html:
                `<p>${data.datoCmsBlog.publishedOn}</p>` +
                data.datoCmsBlog.pageContent,
            }}
          />
          {data.datoCmsBlog.writer !== null && (
            <div
              style={{
                marginTop: 40,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <div
                  style={{
                    background: "#c4c4c4",
                    width: 72,
                    height: 72,
                    marginRight: 24,
                    borderRadius: 36,
                    overflow: "hidden",
                  }}
                >
                  <Img
                    fluid={data.datoCmsBlog.writer.personImage.fluid}
                    alt={data.datoCmsBlog.writer.personImage.alt}
                    title={data.datoCmsBlog.writer.personImage.title}
                  />
                </div>
              </div>
              <div style={{ flex: "1" }}>
                <p
                  style={{
                    fontFamily: "'Roboto Mono', monospace",
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  {data.datoCmsBlog.writer.personName}
                  <br />
                  {data.datoCmsBlog.writer.personTitleCompany}
                </p>
              </div>
            </div>
          )}
        </div>
      </Container>
      <Footer path={path} />
      <NormalizeCSS />
      <GlobalStyles />
      <Favicon />
      <Menu path={path} />
      <Helmet
        meta={[
          {
            name: "twitter:creator",
            content: data.datoCmsBlog.writer.twitterHandle,
          },
          {
            name: "author",
            content: data.datoCmsBlog.writer.personName,
          },
          {
            property: "article:published_time",
            content: data.datoCmsBlog.isopub,
          },
        ]}
      />
      <CookiePopup path={path} />
    </>
  );
};

export const query = graphql`
  query($id: String!) {
    datoCmsBlog(id: { eq: $id }) {
      title
      pageContent
      publishedOn(formatString: "d.M.YYYY")
      isopub: publishedOn(formatString: "YYYY-MM-DD")
      smallExcerpt
      excerpt
      blackOrWhiteBox
      heroImage {
        url
      }
      writer {
        personImage {
          fluid {
            ...GatsbyDatoCmsFluid_tracedSVG
          }
          alt
          title
        }
        personName
        personTitleCompany
        twitterHandle
      }
      seoMetaTags {
        tags
      }
    }
  }
`;
